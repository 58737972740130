@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/light';
@use '@sats-group/ui-lib/tokens/corner-radius';

.booking-component {
  &__member {
    margin-bottom: spacing.$m;
  }

  &__date-pickers {
    display: flex;
    gap: spacing.$xs;
    flex-wrap: wrap;

    @media (min-width: 600px) {
      gap: spacing.$l;
      flex-wrap: nowrap;
    }
  }

  &__hr {
    align-self: center;
    width: 13px; //NOTE: This is a hr line with a magic width
    margin-top: 28px; // NOTE: Hard coded to make alignment pretty
  }

  &__input {
    flex-grow: 1;
    @media (min-width: 600px) {
      flex-grow: 0;
      width: 280px;
    }
  }
}
